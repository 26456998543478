@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&family=IBM+Plex+Serif:wght@300;400;500;600;700&display=swap");
* {
  color: #333;
}
body {
  margin: 1rem;
  letter-spacing: 0.075rem;
  font-family: "IBM Plex Serif", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 170%;
  font-size: 1.25rem;
  background-color: #fafafa;
  /* background-color: #f0f0f0; */
}
p {
  /* font-weight: 500; */
  /* font-size: 1.25rem; */
  /* text-align: justify; */
  /* margin-block-start: 0.5rem; */
  margin-block-end: 1.5rem;
}
li {
  padding: 0 0 0.5rem;
}
h1,
h2,
h3,
h4 {
  /* margin-bottom: 0rem; */
  margin-block-end: 0.25rem;
  line-height: normal;
  font-family: "IBM Plex Serif", sans-serif;
}
img {
  border: 1px solid #2B2A2A;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  color: #333;
  text-decoration: none;
  padding-bottom: 2px;
  /* font-weight: 500; */
  /* border-bottom: 1px solid #DE4A14; */
  border-bottom: 1px solid #DE4A14;
}
.breadcrumb {
  border-bottom: none;
}
.breadcrumb:hover {
  border-bottom: 1px solid #DE4A14;
}
@media (hover: hover) {
  a:hover {
    text-decoration: none;
    border-bottom: 2px solid #DE4A14;
  }
}

.selected {
  margin-left: -1rem;
  /* border-left: 0.5rem solid #DE4A14; */
  border-left: 0.5rem solid #fafafa;
  padding-left: 0.5rem;
  color: #DE4A14;
  /* font-weight: 500; */
}

.selected:hover {
  /* font-weight: 500; */
  border-bottom: none;
  /* color: black; */
}
